/* ==========================================================================
	Footer
	========================================================================== */

.footer {
	text-align: center;
	// background-color: $body-bg;
	font-size: $font-size-base;
	// color: $gray;
	min-height: 25rem;
	display: flex;
	align-items: center;

	a {
		// color: $gray-lighter;

		&:hover {
			color: yellow;
		}
	}

	@media(min-width: $screen-sm-min) {
		text-align: left;
	}
}

.footer_address {
	line-height: 2;
}

.list-footer {
	
	>li {
		padding-bottom: 1rem;
	}

	@media (max-width: $screen-xs-max) {
		padding-top: 1rem;
		margin-top: 1rem;
		border-top: solid 1px $hr-border;
	}
}

.list-footer--logo {
	margin-top: 1rem;
	padding-top: 1rem;

	img {
		height: 2.5rem;
	}
}

.g-play__img {
	width: 9rem;
	margin-top: 1rem;
}
