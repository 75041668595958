/* ==========================================================================
  Fiche dtail
  ========================================================================== */

.fiche-detail {
  position: relative;

  .panel-body {
    padding: .5rem;

    @media(min-width: $screen-sm-min) {
      @include padding(3rem);
    }
  }
}

.fiche-detail_label {
  position: absolute;
  z-index: 2;
  top: 1rem;
  right: 1rem;
}


.fiche-detail_container-col-img {
  width: 640px;
  max-width: 100%;
  margin: 0 auto;
}

.fiche-detail_wrapper-img {
  margin: 0 -.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.col-detail_img-main {
  width: 100%;
  padding: 0 .5rem;
  margin-bottom: 1rem;
}

.col-sm-detail {
  width: 20%;
  padding: 0 .5rem;

  img {
    display: block;
    width: 100%;
    height: 3.5rem;
    object-fit: cover;
    margin-bottom: 1rem;

    @media(min-width: $screen-sm-min) {
      height: 5rem;
    }

    @media(min-width: $screen-lg-min) {
      height: 7rem;
    }
  }
}


.fiche-detail_caption {

  @media(min-width: $screen-md-min) {
    @include padding(1.5rem);
  }
}


.label-brand {
  margin-bottom: 1rem;
  
  img {
    display: inline-block;
    max-width: 150px;
    max-height: 50px;
  }

  span {
    font-size: $font-size-small;
    margin-left: 1rem;
  }
}


.fiche-detail_advrt {
  @extend .list-unstyled;
}

.advrt--card {
  padding: 1rem 0;
  border-bottom: solid 2px $gray-lighter;
  width: 100%;
  display: flex;
  align-items: center;

  img {
    display: block;
    height: 1.5rem;
  }

  span {
    display: block;
    font-size: .75rem;
    margin-left: 1rem;
  }
}

.fiche-detail_desc {
  font-size: $font-size-small;
  line-height: 1.7;
}

.slider-proposition.slick-dotted {

  @media(min-width: $screen-lg-min) {
    width: 95%;
    margin: auto;
    margin-bottom: 5rem;
  }
}

.table tr {
  border-top: solid 2px #eee;
}
