/* ==========================================================================
  Fiche rsum
  ========================================================================== */

.fiche-resum {
  @extend .center-block;
  position: relative;
  overflow: hidden;
  max-width: 27rem;
}

.fiche-resum-slick {
  margin: 0 .6rem;
  text-align: left;
}

.fiche-resum_label {
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.fiche-resum_img-crop {
  display: block;
  height: 20rem;
  width: 100%;
  object-fit: cover;
}

.fiche-resum_body {
  border-top: solid 1px $panel-default-border;
}

.fiche-resum_titre-1 {
  font-size: $font-size-large;
  color: $headings-color;
}

.fiche-resum_titre-2 {
  font-size: $font-size-base;
  color: $headings-color;
}

.fiche-resum_desc {
  font-size: $font-size-small;
}

.fiche-resum_bottom {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($brand-second, .7);
  opacity: 0;
  transition: ease .6s;

  .btn-link {
    color: white;
    font-weight: 600;
    border: solid 1px white;
    border-radius: 3rem;
    position: relative;
    top: 2rem;
    transition: top ease .3s, opacity ease .3s;
  }
}

.fiche-resum:hover {

  .fiche-resum_bottom  {
    opacity: 1;
  }

  .btn-link {
    top: 0;
    opacity: 1;
  }
}
