/* ==========================================================================
	Main
	========================================================================== */

.main {
	@extend .clearfix;
	min-height: 50vh;
}

/* ==========================================================================
	Col Main
	========================================================================== */

.col-main {
	@extend .col-md-9;
	@extend .col-md-push-3;
	padding-bottom: 6rem;
}

/* ==========================================================================
	Col Aside
	========================================================================== */

.col-aside {
	@extend .col-md-3;
	@extend .col-md-pull-9;
	padding-top: 3rem;
}
