/* ==========================================================================
   Accueil
   ========================================================================== */

.header-accueil {
  // background-color: rgba($brand-tierce, .75);
  border-bottom: solid $brand-second 3px;
  @extend .text-center;
  @extend .section-noire;

  padding: .5rem;
  // margin-bottom: 1rem;
}

.logo-accueil {
  display: inline-block;
  margin: 1rem auto;
  @extend .img-responsive;

  >img {
    @extend .img-responsive;
    display: inline-block;
    max-height: 10rem;
  }
}

.main-accueil {
  // min-height: 40vh;
}

.btn-accueil {
  @extend .btn;
  @extend .btn-lg;
  @extend .btn-primary;
  margin: 3% auto;
  text-transform: uppercase;
  font-weight: 700;
}

.footer-accueil {
  @extend .text-center;
  padding: 5%;
  background-color: rgba($body-bg, .8);
  overflow: hidden;

  h1 {
    font-size: $font-size-h2;
  }
}

.col-footer-accueil-left {
  @extend .col-sm-6;
  @extend .text-left;
  
  @media (max-width: $screen-xs-max) {
    text-align: center;
  }
}

.col-footer-accueil-right {
  @extend .col-sm-6;
  @extend .text-right;
  
  @media (max-width: $screen-xs-max) {
    text-align: center;
    padding-top: 20px;
  }
}


/* ==========================================================================
  Accueil
  ========================================================================== */

.parallax-window {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  text-align: center;
  overflow: hidden;
}


.parallax-window-accueil {
	@extend .parallax-window;
  min-height: 100vh;
  background: linear-gradient(to bottom, rgba(black, 0) 0%, rgba(black, .6) 100%);
  
  >a {
    display: inline-block;
    position: relative;
    font-size: 4rem;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    color: white;

    >img {
      @extend .img-responsive;
      max-width: 90%;
      margin: 0 auto;
      transition: transform ease-out .3s;
    }

    >span {
      display: inline-block;
      transition: letter-spacing ease-out .3s;
    }

    @media(min-width: $screen-sm-min) {
      font-size: 6.5rem;
    
      &:hover {
        opacity: .5;
  
        >img {
          transform: scale(1.1);
        }

        >span {
          letter-spacing: 1rem;
        }
      }
    }

    @media(min-width: $screen-md-min) {
      font-size: 6rem;
    }
  }
}


/* ==========================================================================
  Footer Accueil
  ========================================================================== */

.footer-accueil {
  @extend .text-center;
  padding: 5% 0;

  h1 {
    font-size: $font-size-large;
  }
}

.col-footer-accueil-left {
  @extend .col-sm-6;
  @extend .text-left;
  
  @media (max-width: $screen-xs-max) {
    text-align: center;
  }
}

.col-footer-accueil-right {
  @extend .col-sm-6;
  @extend .text-right;
  
  @media (max-width: $screen-xs-max) {
    text-align: center;
    padding-top: 20px;
  }
}