.maxi-best-of {
    @extend .dropdown;
    position: static !important;
}

.maxi-best-of_dropdown {
    @extend .dropdown-menu;
    @extend .animated;
    @extend .fadeInUp;
    animation-duration: .2s;
    animation-timing-function: ease-out;
    left: 0;
    right: 0;
    padding: 0;
    border: none;

    @media(min-width: $screen-md-min) {
        right: 0;
        top: 4rem;
        border-radius: $border-radius-large !important;
        max-height: 75vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.maxi-best-of_container {
    @extend .list-unstyled;
    @include padding(1rem);
    margin: 0;
    display: flex;
    flex-flow: row wrap;

    ul {
        @extend .list-unstyled;
        display: flex;
        flex-flow: row wrap;
        width: 100%;
    }

    a {
        display: block;
        text-transform: none;
        text-shadow: none;
        text-decoration: none;
        color: $brand-second;

        &:hover {
            color: $brand-primary;
        }
    }

    >li {
        width: 14rem;
        margin: .5rem;
        background-color: $list-group-bg;
        border-radius: $border-radius-base;
        overflow: hidden;
    }

    .category-title {
        font-size: $font-size-base;
        margin-bottom: .5rem;
        padding: 10px;
        font-size: .9rem;
        font-weight: 500;
        background-color: $gray-lighter;
        border-radius: $border-radius-base;
    }
}

.maxi-best-of_sub2 {

    >li>a {
        padding: 5px 10px;
        font-size: .8rem;
        background-color: $list-group-bg;
    }
}

.maxi-best-of_sub3 {

    >li>a {
        padding: 2px 10px;
        padding-left: 10px;
        font-size: .7rem;
        border-bottom: solid 1px darken($list-group-bg, 4%);
        background-color: darken($list-group-bg, 2%);

        &::before {
            content: '-';
            padding-right: 5px;
        }
    }
}

.maxi-best-of_sub4 {

    >li>a {
        padding: 2px 10px;
        padding-left: 15px;
        font-size: .7rem;
        border-bottom: solid 1px darken($list-group-bg, 6%);
        background-color: darken($list-group-bg, 4%);

        &::before {
            content: '-';
            padding-right: 5px;
        }
    }
}

.maxi-best-of__promo {
    @include padding(1.5rem);
    background-color: $gray-lighter;
    height: 100%;
}

.maxi-best-of__promo-title {
    display: block;
    margin-bottom: .7rem;
    font-weight: 600;
    color: $brand-second;

    &:hover {
        color: $brand-primary;
    }

    i {
        width: 1.5rem;
    }
}
