//-- Spacing

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}


.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mx-1 {
  @include margin-left(1rem);
  @include margin-right(1rem);
}

.mx-2 {
  @include margin-left(2rem);
  @include margin-right(2rem);
}

.mx-3 {
  @include margin-left(3rem);
  @include margin-right(3rem);
}

.mx-4 {
  @include margin-left(4rem);
  @include margin-right(4rem);
}

.mx-5 {
  @include margin-left(5rem);
  @include margin-right(5rem);
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-1 {
  @include margin-top(1rem);
  @include margin-bottom(1rem);
}

.my-2 {
  @include margin-top(2rem);
  @include margin-bottom(2rem);
}

.my-3 {
  @include margin-top(3rem);
  @include margin-bottom(3rem);
}

.my-4 {
  @include margin-top(4rem);
  @include margin-bottom(4rem);
}

.my-5 {
  @include margin-top(5rem);
  @include margin-bottom(5rem);
}


.mt-0 {
  margin-top: 0;
}

.mt-1 {
  @include margin-top(1rem);
}

.mt-2 {
  @include margin-top(2rem);
}

.mt-3 {
  @include margin-top(3rem);
}

.mt-4 {
  @include margin-top(4rem);
}

.mt-5 {
  @include margin-top(5rem);
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-1 {
  @include margin-bottom(1rem);
}

.mb-2 {
  @include margin-bottom(2rem);
}

.mb-3 {
  @include margin-bottom(3rem);
}

.mb-4 {
  @include margin-bottom(4rem);
}

.mb-5 {
  @include margin-bottom(5rem);
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  @include margin-right(1rem);
}

.mr-2 {
  @include margin-right(2rem);
}

.mr-3 {
  @include margin-right(3rem);
}

.ml-1 {
  @include margin-left(1rem);
}

.ml-2 {
  @include margin-left(2rem);
}

.ml-3 {
  @include margin-left(3rem);
}


.py-0 {
  padding-bottom: 0;
  padding-top: 0;
}

.py-1 {
  @include padding-top(1rem);
  @include padding-bottom(1rem);
}

.py-2 {
  @include padding-top(2rem);
  @include padding-bottom(2rem);
}

.py-3 {
  @include padding-top(3rem);
  @include padding-bottom(3rem);
}

.py-4 {
  @include padding-top(4rem);
  @include padding-bottom(4rem);
}

.py-5 {
  @include padding-top(5rem);
  @include padding-bottom(5rem);
}

.py-8 {
  @include padding-top(8rem);
  @include padding-bottom(8rem);
}

.py-10 {
  @include padding-top(10rem);
  @include padding-bottom(10rem);
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  @include padding-left(1rem);
  @include padding-right(1rem);
}

.px-2 {
  @include padding-left(2rem);
  @include padding-right(2rem);
}

.px-3 {
  @include padding-left(3rem);
  @include padding-right(3rem);
}

.px-4 {
  @include padding-left(4rem);
  @include padding-right(4rem);
}

.px-5 {
  @include padding-left(5rem);
  @include padding-right(5rem);
}


.pt-1 {
  @include padding-top(1rem);
}

.pt-2 {
  @include padding-top(2rem);
}

.pt-3 {
  @include padding-top(3rem);
}

.pt-4 {
  @include padding-top(4rem);
}

.pt-5 {
  @include padding-top(5rem);
}

.pb-1 {
  @include padding-bottom(1rem);
}

.pb-2 {
  @include padding-bottom(2rem);
}

.pb-3 {
  @include padding-bottom(3rem);
}

.pb-4 {
  @include padding-bottom(4rem);
}

.pb-5 {
  @include padding-bottom(5rem);
}

.mt--25p {
  @media (min-width : $grid-float-breakpoint){
    margin-top: -15%;
  }
}
