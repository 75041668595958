
// -- Buttons


.btn-second {
  @include button-variant($btn-second-color, $btn-second-bg, $btn-second-border);
}
.btn-white {
  @include button-variant($btn-white-color, $btn-white-bg, $btn-white-border);
}

// surcharge property
.btn-white {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    background-color: darken($gray-lighter, 10%);
    border-color: darken($gray-lighter, 10%);
  }
}

.btn:focus {
  outline: none;
}

.btn-outline {
  background-color: transparent;
  color: inherit;
  transition: all 0.5s;
}

.btn-primary.btn-outline {
  color: $brand-primary;
}

.btn-success.btn-outline {
  color: $brand-success;
}

.btn-info.btn-outline {
  color: $brand-info;
}

.btn-warning.btn-outline {
  color: $brand-warning;
}

.btn-danger.btn-outline {
  color: $brand-danger;
}

.btn-white.btn-outline {
  color: $gray-lighter;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover,
.btn-white.btn-outline:hover {
  color: #fff;
}

//-- if two buttons are siblings, apply margin-left on the second
.btn {
  & + & {
    margin-left: 1rem;
  }
}


.button-link {
  padding: 0;
  border: none;
  background: transparent;
  color: $link-color;

  &:hover {
    color: $link-hover-color;
  }
}


//------ Labels

.label-second {
  @include label-variant($label-second-bg);
}
.label-white {
  @include label-variant($label-white-bg);
  color: $label-white-color;
}
