// Inserez ici votre banque de classes perso

.o-hide {
  overflow: hidden;
}
.shadow {
	box-shadow: 0px 5px 15px rgba($text-color, .7);
}
// Inserez ici votre banque de classes perso
body {
// background: url(../images/homeBG.jpg) top center no-repeat #fff fixed;
background-color: $body-bg;
// background: url(../images/homeBG.svg);
background-position: right top fixed;
background-repeat: no-repeat;
background-size: cover;
}
.rowflex {
display: flex;
flex-flow: row wrap;
align-items: center;
justify-content: center;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6, .superside {
font-family: 'Imbue', serif;
font-weight: 500;
// font-style: italic;
color: $brand-primary;
margin-bottom: 2rem;
small {
// font-style: italic;
color: darken($brand-second, 40%) !important;
display: block;
padding-top: 5px;
}
}

.page-header, h1.page-header {
@extend .animated;
@extend .fadeIn;
@extend .text-center;
animation-delay: .3s;
margin: 0 auto 3rem auto !important;
// margin: .5rem auto 2rem auto !important;
// margin: -4rem auto 2rem auto !important;
padding: 5rem 0 4rem 0 !important;
@extend .section-blanche;
border-bottom: solid .1rem $brand-second;
// @extend .bloc-primary;
// background-color: $brand-primary;
// border-radius: 0 0 5rem 5rem;
// color: $brand-primary;
// h1,p.h1 {
// @extend .animated;
// @extend .fadeInUp;
// animation-delay: .6s;
// margin: 20px auto;
// padding: 0 4vw;
small {
@extend .animated;
@extend .fadeInUp;
// color: darken($brand-second, 20%);
animation-delay: .9s;
// }
}
}

// .navbar-centered {
// @media (min-width: $grid-float-breakpoint) {
// .navbar-nav {
// display: inline-block;
// float: none;
// vertical-align: top;
// }

// .navbar-collapse {
// }
// }
// }
// .sticky-top {
// position: sticky;
// top: 0;
// }
// .navbar {
// @extend .sticky-top;
// @extend .navbar-centered;
// text-align: center;
// font-size: .9rem;
// // letter-spacing: -1px;
// }

.bloc-contenu {
	// border-radius: 1rem !important;
	background-color: rgba($body-bg, .3);
	// box-shadow: $box-shadow;
	box-shadow: 0px 5px 10px rgba($text-color, .25);
	overflow: hidden;
	position: relative;
	z-index:10;
	color: $text-color;
	padding: 2rem 2vw 2rem 2vw;
	margin: 1rem auto;
}
.bloc-round {
	max-height: 20rem;
	max-width: 20rem;
	font-size: 1.1rem;
	radius: 20rem !important;
	padding: 1.3rem !important;
	margin: 2rem !important;
}
.bloc-primary {
	@extend .bloc-contenu;
	background: linear-gradient(5deg, $brand-primary, lighten($brand-primary, 10%));;
	color: $body-bg;
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
		color: $body-bg !important;
	}
	a, small {
		color: $brand-second;
	}
}
.bloc-second {
	@extend .bloc-contenu;
	background: linear-gradient(5deg, $brand-second, lighten($brand-second, 5%));;
	color: $body-bg;
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
		color: lighten($brand-primary, 10%) !important;
	}
	a, small {
		color: $brand-tierce;
	}
}
section {
	padding: 20px 0!important;
}
.section-blanche {
	background-color: rgba($body-bg, .75);
}
.section-primary {
	background-color: rgba($brand-primary, .95);
	color: $body-bg;
	h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
	color: $body-bg;
	}
	a {
		color: $brand-second;
	}
}

.section-second {
	background-color: rgba($brand-second, .95);
	// color: $body-bg;
	a, small {
		color: $brand-primary;
	}
}

.section-noire {
	// background-color: rgba($text-color, .85);
	background-color: rgba($gray-darker, .85);
	color: white;

	h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6, {
	color: white !important;
}
	a, small {
		color: $brand-second !important;
	}
}

.fond-image-section {
&::after{
content:'';
position: absolute;
top:0;
left:0;
right:0;
bottom:-100vh;
background-color: rgba(darken($brand-primary, 25%), .45);
z-index: -1;
}
}

.section-image {
// @extend .fond-image-section;
min-height: 60vh;
padding: 10vh 15%;
// margin: 40px 0;
// .container-max, .container, .conteneur, hr {
	position: relative;
	z-index: 10;
}

.section-image-01 {
	// @extend .fond-image-section;
	background: url(../images/charte/001.jpg) bottom center fixed red;
	background-size: cover;
	overflow: hidden;
	// .container-max, .container, .conteneur {
		position: relative;
		z-index: 10;
	// }
}

.img-intro {
	@extend .animated;
	@extend .fadeInup;
	@extend .img-responsive;
	@extend .center-block;
	border-top: solid 3px $brand-primary;

	border-bottom: solid 3px $brand-primary;
	animation-delay: .7s;
	margin: 0rem auto -5rem auto;
}
.delai-001 {
	@extend .animated;
	@extend .fadeInUp;
	animation-delay: .3s;
}
.delai-002 {
	@extend .animated;
	@extend .fadeInUp;
	animation-delay: .5s;
}
.delai-003 {
@extend .animated;
@extend .fadeInUp;
animation-delay: .7s;
}
.delai-004 {
@extend .animated;
@extend .fadeInUp;
animation-delay: .9s;
}
.diapo-bandeau {
	@extend .animated;
	@extend .fadeInLeft;
	animation-delay: .2s;
	border: solid 3px $brand-second;
	position :relative;
	// box-shadow: $box-shadow;
	img {
		@extend .img-responsive;
		max-height: 30rem !important;
	}
}
.texte-primary {
	color: $brand-primary;
}
.texte-second {
	color: $brand-second;
}
.texte-blanc {
color: #fff !important;
text-shadow: 1px 2px 4px rgba($text-color, .25);
}
.moins-fat {
	font-size: 1.5rem;
}
.texte-massif {
	font-size: 2.5rem;
}
.img-bulle {
@extend .img-circle;
@extend .img-responsive;
@extend .center-block;
@extend .text-center;
// height: 30px;
max-height: 10rem;
// border-radius: 100px;
// border: solid 2px $brand-primary;
// border: solid .1rem darken($brand-primary, 25%);
// box-shadow: $box-shadow;
margin: 20px auto;
background-color: #fff;
padding: 0;
position: relative;
z-index: 500;
transition: transform ease-out .5s;
&:hover {
border: solid .2rem lighten($brand-primary, 5%);
transition: transform ease-in .5s;
}
}
.multi-pills {
@extend .list-inline;
>li {
color: #fff !important;
border: solid 3px white;
border-radius: 20px;
padding: 5px 1.6rem;
margin: 05px;
background-color: $brand-primary !important;
>a {
padding: 50px !important;
color: #fff !important;
}
}
}
.bloccueil {
margin: 10vh auto;
max-width: 60rem;
overflow: hidden;
position: relative;
z-index: 100;
@extend .animated;
@extend .fadeInDown;
@extend .container;
background: rgba($body-bg, .75);
background: white;
// border: solid 5px white;
// box-shadow: $box-shadow;
padding: 0rem;

.content-bloccueil {
display: flex;
flex-direction: column;

@media(min-width: $screen-md-min) {
flex-direction: row;
}
}

.side-bloccueil-left {
padding: 1.7rem;
@extend .text-center;
width: 100%;

@media(min-width: $screen-md-min) {
width: 65%;
}
}

.side-bloccueil-right {
// border-left: solid 3px $brand-second;
overflow: hidden;
margin: 0;
padding: 0;
width: 100%;

@media(min-width: $screen-md-min) {
width: 35%;
}

>img {
@extend .center-block;
@extend .img-responsive;
width: 40%;
@media(min-width: $screen-md-min) {
width: 100%;
}
}
}
}

.footer {
font-size: 1rem;
padding: 0 0 50px 0;
background: linear-gradient(45deg, lighten($brand-primary, 20%), lighten($brand-primary, 5%));;
color: white;
a {
color: lighten($brand-tierce, 5%);
}
}

.margin-neg {
	margin-top: -5rem;
	padding-top: 5rem;
}
.cinquieme {
margin: 20px auto;
padding: 20px 0; 
@extend .list-inline;
// @extend .text-center;
@extend .center-block;

>li {
@extend .col-sm-6;
@media (min-width: $screen-md-min) {
width: 20%;
}
}
}

table {
	color: $text-color;
}


.bg-second {
	background: linear-gradient(5deg, $brand-second, lighten($brand-second, 5%));;
}